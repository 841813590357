.selectField{
  flex: 1 1;
  margin: 10px;
  min-width: 160px;
}

.commonField{
  flex: 1 1;
  margin: 10px;
  min-width: 160px;
}

.timeRuleField{
  flex: 360px;
  margin: 10px;
}

.inputSize{
  font-size: 12px;
  min-height: 35px !important;
}

.wide {
  min-width: 355px;
  width: 355px;
}
