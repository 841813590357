.app_container {
  padding-right: 50px;
  padding-left:50px;
  max-width: 1200px;
  margin:0 auto;
}

.brand {
  font-size: 48px !important;
  font-family: 'Ruda', sans-serif;
  font-weight: 900;
  color:inherit;
}

.bar {
  padding-left: 0px !important;
}

.background {
  background-color: lightgray;
}
