.container{
  font-family: inherit;
  font-size: 14px;
  color: #373A3C;
  
}
.title{
  font-weight: normal;
  margin: 0px;
  text-overflow: wrap;
  
}
.value{
  font-weight: 900;
  margin: 0px;
  flex-wrap: wrap;
  word-break: break-all
}

.flexWrap{
  width: 190px;
  text-overflow: wrap;
  margin-bottom: 20px;
  margin: 5px;
}