.filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
}

.filterwrapper {
  max-width: 250px;
  display: flex;
  flex-direction: column;
  margin: 12px;
  margin-right: 16px;
}

.spacer {
  width: 30px;
}