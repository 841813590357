.options {
  display: flex;
  flex-direction: column;
  background-color: #FAFAFF;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #DDDDDD;
  margin-bottom: 10px;
}

.loadingOverlay {
  z-index: 1000;
  background-color: rgba(50, 50, 50, .1);
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  vertical-align: center;
  text-align: center;
}

.hidden {
  visibility: hidden;
}

.relative  {
  position: relative;
}

.quickoptions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rightoptions {
  display: flex;
  flex-direction: row;
}

.pagination {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
}

.spacer {
  display: flex;
  flex: 1;
}

.buttonsContainer{
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.formNavigationButton{
  margin-left: 10px;
  font-size: inherit;
}
