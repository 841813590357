.container{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.title{
  font-size: 16px;
  margin-left: 0px;
  font-family: 'Ruda', sans-serif;
  font-weight: 900;
  color:inherit;
}

.goBack{
  margin-right: 32px;
}

.children{
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}
