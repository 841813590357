
.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background-color: #F5F6F7;
  min-height: 100vh;
  padding: 0px 32px;
  flex-direction: column;
}

.page {
  display: flex;
  flex-direction: column;
  background-color: white;
  max-width: 1000px;
  min-width: 1000px;
  width: 1000px;
  padding: 32px;
  box-shadow: 0px 4px 4px rgba(0,0,0,0.3);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
}

.larger {
  font-size: medium;
}
