
.wrapper {
    display: flex;
    flex-direction: row;
}

.input {
    min-height: 35px !important;
}

.country {
    width: 32px !important;
}

.postcode {
    width: 70px !important;
}

.city {

}
