.container{
  width: 70%;
  margin-bottom: 50px;
}
.formwrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items:flex-start;
}
.descriptionContainer{
  padding-right: 40px;
  width: 40%;
}
.description{

}
.formContainer{
  width:60%;
}

.form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin : -10px;
}

.separator{
  width: 100%;
  height: 1px;
  background-color:rgba(0, 0, 0, 0.15) ;
  margin-bottom: 20px;
}

.submit {
  display: none;
}

.buttonsContainer{
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 20px;
}

.asterix {
  display: block;
  margin-top: 20px;
}

.saveButton{
  margin-left: 10px;
  font-size: inherit;
}

.clearButtton{
  font-size: inherit;

}
