.body {
  max-width: 795px;
  min-width: 795px;
  margin: auto;
  margin-top: 0px;
  padding-right: 60px;
}

@media print {
  .body {
    max-width: 90%;
    width: 90%;
    padding-right: 0px;
    margin-left: 5%;
    margin-right: 5%;
  }
}
