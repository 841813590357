* {
  margin: 0;
  padding: 0;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  -webkit-font-smoothing: subpixel-antialiased;
  scroll-behavior: smooth;
}

body {
  font-family: 'Ruda';
  background-color: #F5F6F7;
}

input {
  font-family: inherit;
  font-size: inherit
}

p {
  margin: 0
}

@media only screen and (max-width: 900px) {
  html {
    font-size: 57%;
  }
}

span,
p,
a {
  font-family: Ruda;
}

.unset-h {
  height:  unset !important;
}

.input-group-text {
  background-color: #ffffff;
}

.input-group-prepend {
  border-right: none;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
}

.input-group-middle {
  border-right: none;
  border-left: none;
  border-radius: unset;
}

.input-group-append {
  border-left: none;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
}

@page {

  margin: 0mm;
  padding: 0mm;
}

@media print {
  body {
    margin: 0px;
    padding: 0px;
  }
}

body {
  -webkit-print-color-adjust: exact !important;
}

.react-calendar__tile--active {
  background-color: blue !important;
  color: white;
}

.react-calendar {
  pointer-events: none;
  margin: 4px;
}

textarea {
  height: 100px;
}
