.screen_wrapper{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container{
  display: flex;
  flex:1;
  border-radius: 16px;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  min-height: 500px;
  border-radius: 16px 16px 16px 16px;
  align-items: center;
  justify-content: center;
}
.center_form_container{
  background-color: #FFF;
  padding: 40px;
  box-shadow: 0px 4px 4px rgba(0,0,0,0.3);
}

.logo{
  width: 116px;
  height: auto;
  margin-bottom: 16px;
}

.title{
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  color: #000;
  font-size: 30px;
  margin-bottom: 16px;
}

.description{
  font-weight: lighter;
  font-family: 'Roboto', sans-serif;
  color: rgba(0,0,0,0.3);
  font-size: 12px;
  margin-bottom: 40px;
}

.input{
  height: 30px;
  margin-bottom: 8px;
}

.button{
  background-color: #79acd2;
  border: none;
  width: 100%;
  height: 30px;
  font-size: 12px;
  margin-bottom: 30px;
  margin-top: 50px;
}

.anchor_tag{
  transition: all 5s;
}

.forgot_password{
  text-decoration: none;
  text-align: center;
  width: 100%;
  font-size: 12px;
}