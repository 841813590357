.list {
  border: 1px solid #DDDDDD;
}

.body {
  border: 1px solid #DDDDDD;
  background-color: #fff;
}

.header {
  background: #FAFAFF;
}